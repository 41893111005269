<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 确认订单 -- 废弃
 * @Date: 2020-11-11 11:00:38
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-13 11:13:06
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/comeOn/Confirmorder.vue
-->
<template>
  <div class="content">
    <div class="order_details">
      <div class="or_title">加油订单</div>
      <ul>
        <li>
          <span>油站名称</span><span>{{ query.gas_name }}</span>
        </li>
        <li>
          <span>油号/枪号</span><span>{{ query.name }} {{ query.gun_no }}号枪</span>
        </li>
        <li>
          <span>金额</span><span><span
            style="color:#999999;margin-right:5px;font-size:13px;"
          >约{{ litre }}L</span>¥ {{ pay_amount }}</span>
        </li>
      </ul>
    </div>
    <div class="Discount order_details">
      <div class="or_title">优惠奖励</div>
      <ul class="dis_list">
        <li><span>优惠直降</span><span>-¥0.00</span></li>
        <li><span>奖励</span><span>-¥0.00</span></li>
      </ul>
    </div>
    <div class="rule" @click="rules()">
      <van-icon name="warning" color="#F7263C" size="15" />
      <span>优惠规则</span>
    </div>
    <div class="payment">
      <div class="pay_title" :span="24">支付</div>
      <van-row class="pa_li">
        <van-col :span="2"><img src="@/assets/images/zf.png" alt=""></van-col>
        <van-col :span="20" class="pa_t" style="text-align:left;">余额支付</van-col>
        <van-col :span="2">
          <van-checkbox v-model="checked" disabled checked-color="#F7263C" />
        </van-col>
      </van-row>
      <van-row class="pa_li">
        <van-col :span="2"><img src="@/assets/images/WeChat_z.png" alt=""></van-col>
        <van-col :span="20" class="pa_t" style="text-align:left;">微信支付</van-col>
        <van-col :span="2">
          <van-checkbox v-model="checkedw" checked-color="#F7263C" />
        </van-col>
      </van-row>
    </div>
    <van-row class="total">
      <van-col :span="18" class="total_cen">
        <van-row type="flex" class="cen_le">
          <van-col :span="11" class="cen_t1">合计待支付</van-col>
          <van-col
            :span="7"
            class="cen_t2"
          >¥{{ pay_amount | pr_status }}
          </van-col>
          <van-col :span="6">
            <van-dropdown-menu direction="up">
              <van-dropdown-item ref="item" title="明细">
                <div class="total_box">
                  <ul>
                    <li>
                      <span>总金额</span><span>¥{{ pay_amount | pr_status }}</span>
                    </li>
                  </ul>
                </div>
              </van-dropdown-item>
            </van-dropdown-menu>
          </van-col>
        </van-row>
      </van-col>
      <van-col
        :span="6"
        class="tot_ok"
        @click="getcreateOilOrder()"
      >确认支付
      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { createOilOrder, rePay } from '@/services/comeOn.js'
import { formatNumber } from '@/utils/common/utils'
import { Cell, CellGroup, Checkbox, Col, DropdownItem, DropdownMenu, Icon, Row, Toast } from 'vant'

Vue.use(Icon)
  .use(Toast)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Cell)
  .use(CellGroup)
  .use(Col)
  .use(Row)
  .use(Checkbox)

export default {
  filters: {
    pr_status(v) {
      if (v === '') {
        return '0.00'
      } else {
        return formatNumber(v, 2, '.', ',')
      }
    }
  },
  data() {
    return {
      query: this.$route.query,
      pay_amount: this.$route.query.pay_amount,
      litre: this.$route.query.litre,
      checked: false,
      checkedw: true,
      orderNo: '',
      Uid: '',
      openId: ''
    }
  },
  created() {
    const _self = this
    _self.Uid = localStorage.getItem('uid')
    _self.openId = localStorage.getItem('openid')
  },
  mounted() {

  },
  methods: {
    rules() {
      this.$router.push({
        path: '/PreferentialRules',
        query: {}
      })
    },
    // 提交订单
    getcreateOilOrder() {
      const patmr = {
        'source': '07B6E7106A082D618871140301E9CF36',
        'gas_id': this.query.gas_id,
        'gas_name': this.query.gas_name,
        'other_gas_id': this.query.other_gas_id,
        'number': this.query.number,
        'name': this.query.name,
        'gun_no': this.query.gun_no,
        'amount_gun': this.query.pay_amount,
        'pay_amount': this.query.pay_amount,
        'oil_type': this.query.oil_type,
        'litre': this.query.litre,
        'price_gun': this.query.price_gun,
        'price_unit': this.query.price_unit,
        'paySource': '152',
        'version': '5'
      }
      createOilOrder(patmr).then((res) => {
        if (Number(res.code) === 200) {
          this.orderNo = res.data.orderNo
          this.getrePay()
        }
      })
    },
    // 发起支付
    getrePay() {
      const patmr = {
        'orderNo': this.orderNo,
        'openId': this.openId,
        'payVersion': 3,
        'returnUrl': 'https://www.yuetao.group?odersn=' + this.orderNo
      }
      rePay(patmr).then(res => {
        if (Number(res.code) === 200) {
          const params = res.data.pay.getwayBody
          this.weixinPay(params)
        } else {
          Toast(res.msg)
        }
      })
    },
    weixinPay(params) {
      alert('进微信')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 10px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .order_details {
    border-radius: 7px;
    background-color: #ffffff;
    padding: 5px 10px;

    & > div {
      padding: 5px 0;
    }

    .or_title {
      color: #333333;
      text-align: left;
      font-weight: 500;
      border-bottom: 1px solid #eeeeee;
      font-size: 17px;
    }

    & > ul {
      & > li {
        display: flex;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #eeeeee;
        line-height: 30px;
        color: #333333;
        font-size: 13px;

        & > span:nth-child(1) {
          flex: 14;
          text-align: left;

          & > input {
            margin-left: 10px;
            font-size: 13px;
            color: #f7263c;
            font-weight: 600;
          }

          & > input::-webkit-input-placeholder {
            color: #999999;
          }
        }

        & > span:nth-child(2) {
          flex: 10;
          text-align: right;
        }
      }

      & > li:nth-last-child(1) {
        border: 0;
      }
    }
  }

  .Discount {
    margin-top: 10px;

    .dis_list {
      & > li {
        & > span:nth-child(2) {
          color: #f7263c;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }
  }

  .rule {
    margin: 10px;
    text-align: left;
    display: flex;
    align-items: center;

    & > span {
      color: #333333;
      font-size: 12px;
      padding: 2px;
    }
  }

  .payment {
    background-color: #ffffff;
    padding: 0 10px 10px;
    border-radius: 5px;

    img {
      width: 21px;
      height: 21px;
    }

    .pay_title {
      color: #333333;
      font-size: 17px;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding: 10px 0;
    }

    .pa_li {
      border-bottom: 1px solid #eeeeee;
      padding: 10px 0;
    }

    .pa_t {
      line-height: 25px;
      color: #333333;
    }

    // & > ul {
    //   & > li {
    //     border-bottom: 1px solid #eeeeee;
    //     padding: 10px 0;
    //     & > & > span {
    //       text-align: left;
    //     }
    //   }
    // }
  }

  .total {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .tot_ok {
      background-color: #f7263c;
      color: #fff;
      padding: 15px;
    }

    .total_cen {
      background-color: #ffffff;

      .cen_le {
        align-items: center;

        .cen_t1 {
          font-size: 15px;
          color: #333333;
        }

        .cen_t2 {
          color: #f7263c;
          font-size: 17px;
          font-weight: 600;
          text-align: justify;
          text-justify: newspaper;
          word-break: break-all;
        }

        .total_box {
          padding: 20px;

          & > ul {
            & > li {
              display: flex;
              color: #333333;

              & > span:nth-child(1) {
                flex: 12;
                text-align: left;
                font-size: 15px;
              }

              & > span:nth-child(2) {
                flex: 12;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.van-ellipsis {
  color: #f7263c !important;
  font-size: 10px !important;
}
</style>
